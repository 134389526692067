function disabledFormEdit(){
  $('.disabled-form-builder').each(function(i, obj){
    var form = $(obj);
    form.find("input").attr("disabled", "disabled");
    form.find("textarea").attr("disabled", "disabled");
    form.find("select").attr("disabled", "disabled");
    form.find(".signature-widget input").removeAttr("disabled");
  });
}

document.write(
  unescape("%3Cscript src='https://cdn.ckeditor.com/4.14.0/standard/ckeditor.js' type='text/javascript'%3E%3C/script%3E")
);

document.addEventListener("turbolinks:load", function() {
  $('.selectpicker').selectpicker();

  $('.claim-upload-jobs-table').DataTable({
    pageLength: 25,
    responsive: true,
    "order":[[0, 'asc']],
    dom: '<"html5buttons"B><"topbar"lp>Tgit',
    buttons: [
    ]
  });

  if ($('[name="facility[billing_preferences]"]').length > 0){
    CKEDITOR.replace('facility[billing_preferences]', {height:['400px']});
  }
});

$(document).ready(function(){
  $('.usa-number').mask('(000) 000-0000');

  insurancePicker($('.insurance-select'));
  npi_select();
})

// open intakes modal
$(document).on("click", ".show-intakes-modal", function(){
  $(".ajax-loading").show();
  var id = $(this).attr('data-id');
  var formGuid = $(this).attr('data-form-id');
  $.ajax({
    url: '/clients/show_modal/'+id,
    data: {form_builder_guid: formGuid},
    type: 'GET'
  })
});

$(document).on("click", ".intake_modal_nav_item .pdf-link", function(){
  $(".ajax-loading").show();
});

// close intakes modal
$(document).on("click", ".close-intakes-modal", function(){
  window.location.reload();
});

function submitPatientForm(){
  const form = $('#intake_form')[0];
  const data = new FormData(form);
  $('#finish').html('<i class="fa fa-spin fa-spinner"></i>');
  $('.alert').html('');
  $('.notice').html('');
  if (form.action.includes("/create")){
    var requestType = "POST";
  }
  else{
    var requestType = "PATCH";
  }

  $.ajax({
    type: requestType,
    url: form.action,
    data: data,
    contentType: false, // NEEDED, DON'T OMIT THIS (requires jQuery 1.6+)
    processData: false, // NEEDED, DON'T OMIT THIS
    success: function (result) {
      $('#finish').html('Save');
      if (requestType == "POST"){
        swal({
          title: "Success!",
          text: "Your Client has been successfully saved!",
          type: "success",
          showConfirmButton: true
        },function(){
          window.location = result.action;
        });
      }
      else{
        if (result.error){
          swal({
            title: "Validation!",
            text: result.error,
            html: true,
            type: "warning",
            showCancelButton: true,
            showConfirmButton: true
          },function(isConfirm){
          });
        }
        else{
          swal({
            title: "Success!",
            text: "Your Client has been successfully updated!",
            type: "success",
            showConfirmButton: true
          },function(){
            window.location = result.action;
          });
        }
      }
    },
    error: function (result) {
      $('#finish').html('Save');
      swal('Failure', result.responseJSON.message, 'error');
    }
  });
}

function insurancePicker(element){
  element.selectpicker().ajaxSelectPicker({
    ajax: {
      // data source
      url: '/insurance_carriers/search',

      // ajax type
      type: 'POST',

      // data type
      dataType: 'json',

      // Use "{{{q}}}" as a placeholder and Ajax Bootstrap Select will
      // automatically replace it with the value of the search query.
      data: {
        q: '{{{q}}}'
      },
    },
    // function to preprocess JSON data
    preprocessData: function (data) {
      var i, l = data.length, array = [{name: 'Select Insurance', value: '', text: 'Select Insurance'}];
      if (l) {
        for (i = 0; i < l; i++) {
          array.push($.extend(true, data[i], {
            text : data[i].name,
            value: data[i].value
          }));
        }
      }
      // You must always return a valid array when processing data. The
      // data argument passed is a clone and cannot be modified directly.
      return array;
    }
  });
}

function uploadModal(){
  $('.documents-table').DataTable({
    responsive: true,
    "ordering": false,
    dom: '<"html5buttons"B><"topbar"lp>Tgit',
    buttons: [
    ]
  });

  $('.upload_document').click(function(){
    let patientGuid = $(this).attr('data-id')
    $.get(`/upload_modal/${patientGuid}`)
  })
}

function deleteModal(){
  $('.delete-document').on("click", function(){
    var url = $(this).attr('data-url');
    swal({
      title: "Delete Document",
      text: "Are you sure you want to delete this document?",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#1ab394",
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      closeOnConfirm: false,
      closeOnCancel: true,
      allowOutsideClick: false
    },
    function (isConfirm) {
      if (isConfirm) {
        $.ajax({
          type: 'DELETE',
          url: url,
          success: function (data) {
            swal("Document deleted!", "The document has been deleted", "success");
            $.get(`/uploads/${data.patient_guid}`)
          },
          error: function (data) {
            swal("Failure", "Something went wrong.", "error");
          }
        });
      }
    });
  });
}

$(document).on("click", "#open_file_upload", function(){
  $('#upload_claim_job_file').trigger('click');
});
$(document).on("change", "#upload_claim_job_file", function(){
  $("#claims_upload_btn").click();
});

$(document).on("click", "#claimsSelectAll", function(){
  state = this.checked;
  var checkboxes = document.querySelectorAll('#clients-table input[type="checkbox"]');
  for (var checkbox of checkboxes) {
    checkbox.checked = state;
  }
});

function npi_select(){
  $('.npi-ct-select-idv').selectpicker().ajaxSelectPicker({
    ajax: {
      url: 'https://clinicaltables.nlm.nih.gov/api/npi_idv/v3/search',
      type: 'GET',
      dataType: 'json',
      data: {
        terms: '{{{q}}}',
        q: ''
      }
    },
    // function to preprocess JSON data
    preprocessData: function (data) {
      data = data[3]
      var i, l = data.length, array = [];
      if (l) {
        for (i = 0; i < l; i++) {
          array.push({
            text : data[i][1],
            value: data[i][1],
            data : {
              subtext: (data[i][0] + ", " + data[i][2] + ", " + data[i][3])
            }
          });
        }
      }
      return array;
    },
    preserveSelected: false
  });
}

$(document).on("click", "claim-detail-left-column a", function(){
  $(".ajax-loading").show();
});

function finish_click(){
  $('#finish').click(function(){
    if(checkPatientInfo()){
      submitPatientForm();
    }
  });
}

function hideApptModal(){
  $('#appointments_history_container').html('');
  $('#appointment-history-modal').modal('hide');
  $('.modal-backdrop').remove();
  $('body').css('overflow', 'auto');
}

function patientTeamMemos(patient_guid){
  $.ajax({
    type: 'GET',
    url: "/memos/show_team_summary_memo/"+patient_guid,
    success: function (data) {
    },
    error: function (data) {
    }
  });
}

function showAppointmentHistory(patient_guid){
  if (!patient_guid) {
    swal("Failure", "Client not selected.", "error");
  }
  else {
    $.ajax({
      type: 'GET',
      url: "/appointments/load_appointments_history/"+patient_guid,
        success: function(response) {
          $('#appointments_history_container').html(response);
          $('#appointment-history-modal').modal('show');
        },
        error: function(xhr, status, error) {
            console.error(error);
        }
    });
  }
}


function showConfimation(){
  swal('Note', 'Please redo your associated diagnosis codes in the procedure code table to account for the newly changed diagnosis code.', 'warning');
}

// ALLOW DECIMAL ONLY TWO PLACES
function allow_decimal_two_places(ele, evt){
  var value = ele.val();
  var numbers_value = value.replace(/[^0-9\.]/g, '');
  // var decimal_value = numbers_value.match(/^\d+\.?\d{0,2}/);
  var decimal_value = numbers_value.match(/^\d+\.?\d{0,2}/) || numbers_value.match(/^\.?\d{0,2}/);
  var expected_value = "";
  if (decimal_value){
    expected_value = decimal_value[0];
  }
  else{
    expected_value = numbers_value;
  }
  ele.val(decimal_value);
  if ((evt.which != 46 || self.val().indexOf('.') != -1) && (evt.which < 48 || evt.which > 57)){
    evt.preventDefault();
  }
}

// document.addEventListener("turbolinks:before-cache", function() {
//   $('.dataTable').DataTable().destroy();
// });

window.submitPatientForm = submitPatientForm;
window.insurancePicker = insurancePicker;
window.uploadModal = uploadModal;
window.deleteModal = deleteModal;
window.npi_select = npi_select;
window.finish_click = finish_click;
window.hideApptModal = hideApptModal;
window.patientTeamMemos = patientTeamMemos;
window.showAppointmentHistory = showAppointmentHistory;
window.disabledFormEdit = disabledFormEdit;
window.showConfimation = showConfimation;
window.allow_decimal_two_places = allow_decimal_two_places;
