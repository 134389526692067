// function selectUserRecipient(){
//   $('.user-select').on('click', function () {
//     $.get('/message/user_select')
//   });
// }

document.addEventListener("turbolinks:load", function() {
  var pTable = $('.dt-sort-messages').DataTable({
    select: true,
    pageLength: 25,
    responsive: true,
    "order": [],
    dom: '<"html5buttons"B><"topbar"lp>Tgit',
    buttons: [],
    initComplete: function () {
    }
  });
});

function send_message(is_forwarded=false){
  $('.send-message').click(function () {
    if ($('input[name="addRecipient[]"]').val()?.length < 1  && $('input[name="patient_guids[]"]').val().length < 1  ){
      swal("No Recipient", "Please select a recipient.", "warning");
    }
    else if ($('input[name="subject"]').val().length < 1){
      swal("No subject", "Please enter a subject.", "warning");
    }
    else if ( $('textarea[name="content"]').val().length < 1) {
      swal("No message", "Please enter a message.", "warning");
    }
    else {
      if (is_forwarded){
        let category = $('input[name="category"]').val();
        let recipients = $("input[name='addRecipient[]']").map(function(){return $(this).val();}).get();
        let patientGuids = $('input[name="patient_guids[]"]').val().split(',');
        let subject = $('input[name="subject"]').val();
        let patient = $('#forwardMessage input[name="patient_guid"]').val();
        let message_guid = $('#forwardMessage input[name="original_message_guid"]').val();
        let content = $('textarea[name="content"]').val();
        swal({
          title: "Send message",
          text: "Would you like to send this message?" ,
          type: "warning",
          showCancelButton: true,
          confirmButtonColor: "#1ab394",
          confirmButtonText: "Yes",
          cancelButtonText: "Cancel",
          closeOnConfirm: true,
          closeOnCancel: true,
          allowOutsideClick: false
        }, function(isConfirm){
          if (isConfirm) {
            $.ajax({
              type: 'POST',
              url: '/messages/create',
              data: {
                category: category,
                recipients: recipients,
                subject: subject,
                patient: patient,
                content: content,
                patient_guids: patientGuids,
                message_guid: message_guid,
                is_forwarded: is_forwarded
              },
              success: function(data){
                location.reload();
              },
              error: function(data){
              }
            });
            $('#forwardMessage').modal('toggle');
          }
        });
      }
      else {
        let category = $('input[name="category"]').val();
        let messaging_max_file_limit = $('input[name="messaging_max_file_limit"]').val();
        let messaging_max_file_size_in_mb = $('input[name="messaging_max_file_size"]').val();
        let messaging_max_file_size_in_bytes = messaging_max_file_size_in_mb*1024*1024;
        let recipients = $("input[name='addRecipient[]']").map(function(){return $(this).val();}).get();
        let patientGuids = $('input[name="patient_guids[]"]').val().split(',');
        let msgType = $('input[name="msg_type"]').val();
        let subject = $('input[name="subject"]').val();
        let patient = $('#newMessage input[name="patient_guid"]').val();
        let attachments = [];
        let file_sizes = [];
        let files = $('#newMessage input[name="attachments"]')[0].files;
        if (files.length > messaging_max_file_limit){
          swal(`Only ${messaging_max_file_limit} attachments`, `You can select only ${messaging_max_file_limit} attachments.`, "warning");
        }
        else{
          $.each(files, function( key, value ) {
            if (value.size > messaging_max_file_size_in_bytes){
              file_sizes.push(value.size)
            }
            const reader = new FileReader();
            reader.readAsDataURL(value);
            reader.onload = function () {
              attachments.push(reader.result);
            };
            reader.onerror = function (error) {
              console.log('Error: ', error);
            };
          });
          if (file_sizes.length > 0){
            swal("Maximum file size", `You can not upload any attachments more than ${messaging_max_file_size_in_mb} MB.`, "warning");
          }
          else{
            let content = $('textarea[name="content"]').val();
            swal({
              title: "Send message",
              text: "Would you like to send this message?" ,
              type: "warning",
              showCancelButton: true,
              confirmButtonColor: "#1ab394",
              confirmButtonText: "Yes",
              cancelButtonText: "Cancel",
              closeOnConfirm: true,
              closeOnCancel: true,
              allowOutsideClick: false
            }, function(isConfirm){
              if (isConfirm) {
                $.ajax({
                  type: 'POST',
                  url: '/messages/create',
                  data: {
                    category: category,
                    recipients: recipients,
                    subject: subject,
                    patient: patient,
                    attachments: attachments,
                    content: content,
                    msg_type: msgType,
                    patient_guids: patientGuids
                  },
                  success: function(data){
                    location.reload();
                  },
                  error: function(data){
                  }
                });
                $('#newMessage').modal('toggle');
              }
            });
          }
        }
      }
    }
  });
}

function clearMessage(){
  $('.clear-message').click(function () {
    $("#append-recipients").html("");
    $('input[name="subject"]').val(null);
    $('textarea[name="content"]').val(null);
    $('input[name="patient_guid]').val(null);
    // $('.user-select').replaceWith(`<button type="button" class="btn btn-primary mb-3 user-select" >Select</button>`)
    $('.user-select').html("Select");
    $('.user-select').removeClass("user-selected");
    // selectUserRecipient()
  });
}

function user_search_reset(ele){
  var terms = ele.closest("form").find("input[name='terms']").val();
  var tab = ele.closest("form").find("input[name='tab']").val();
  if (terms){
    // ele.closest("form").find("input[name='terms']").val(null);
    // ele.closest("form").submit();
    $.get(`/messages/user_select_search?tab=${tab}&terms=`)
  }
}

function client_search_reset(ele){
  var terms = ele.closest("form").find("input[name='terms']").val();
  if (terms){
    // ele.closest("form").find("input[name='terms']").val(null);
    // ele.closest("form").submit();
    $.get(`/messages/user_select_search?tab=clients&terms=`)
  }
}

function user_select_row_click(guid, firstname, lastname, data_patient){
  if(data_patient == "true"){
    $(`*[data-patient="true"]`).removeClass('selected');
    $("#append-recipients").find("input[data-patient='true']").remove()
  }

  if($(`#append-recipients #recipient-${guid}`).length == 0){
    var input = document.createElement("input");
    input.type = "hidden";
    input.name = "addRecipient[]";
    input.id = "recipient-" + guid;
    input.value = guid;

    input.setAttribute('data-firstname', firstname);
    input.setAttribute('data-lastname', lastname);
    input.setAttribute('data-patient', data_patient);

    $('#append-recipients').append(input);
  }
  else{
    $(`#recipient-${guid}`).remove()
  }

  $("#append-recipients").find("input[data-patient='true']").appendTo('#append-recipients');

  $(`*[data-id="${guid}"]`).toggleClass('selected')
  // $('.user-select').replaceWith(`<input class="form-control user-select mb-3" readonly>`)
  var allNames = $("input[name='addRecipient[]']").map(function(){return $(this).attr('data-lastname') + ' ' +  $(this).attr('data-firstname');}).get().join(",");
  // $('.user-select').val(allNames);
  $('.user-select').html(allNames);
  $('.user-select').addClass("user-selected");
  // selectUserRecipient()
}

function select_recipient(){
  if ($("input[name='addRecipient[]']").map(function(){return $(this).val();}).get().length === 0 ){
    // $('.user-select').replaceWith(`<button type="button" class="btn btn-primary mb-3 user-select">Select</button>`)
    $('.user-select').html("Select");
    $('.user-select').removeClass("user-selected");
  }
  $('#user-list').modal('hide');
  $("body.fixed-sidebar").addClass("modal-open");
}

function sendReply(){
  $('.reply-message').click(function () {
    if ( $('textarea[name="content"]').val().length < 1) {
      swal("No message", "Please enter a message.", "warning");
    }
    else {
      let guid = $(this).attr('data-id')
      let reply_all;
      if($(this).attr('data-reply-all')){
        reply_all = true
      }
      else {
        reply_all = null
      }
      let category = $('input[name="category"]').val();
      let content = $('textarea[name="content"]').val();
      swal({
        title: "Send message",
        text: "Would you like to send this message?" ,
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#1ab394",
        confirmButtonText: "Yes",
        cancelButtonText: "Cancel",
        closeOnConfirm: true,
        closeOnCancel: true,
        allowOutsideClick: false
      }, function(isConfirm){
        if (isConfirm) {
          $.ajax({
            type: 'POST',
            url: `/messages/reply/${guid}`,
            data: {
              category: category,
              reply_all: reply_all,
              content: content
            },
            success: function(data){
              location.reload();
            },
            error: function(data){
            }
          });
          $('#newMessage').modal('toggle');
        }
      });
    }
  })
}

$(document).on("click", ".show-message td", function(){
  if($(this).hasClass('not-clickable')){
  }
  else {
    let guid = $(this).attr('data-id');
    let bulkMsgGuid = $(this).attr('data-bulk-id');
    $.get(`/messages/${guid}?bulk_msg_guid=${bulkMsgGuid}`)
  }
});

$(document).on("click", ".archive-message", function(){
  let collection = []
  let checkboxes = $('.show-message').find('.select-archive-message')
  checkboxes.each(function () {
    if($(this).prop('checked')){
      let guid = $(this).attr('data-message-guid')
      collection.push(guid)
    }
  })
  if(collection.length > 0 ){
    swal({
      title: "Archive messages",
      text: "Would you like to archives these messages?" ,
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#1ab394",
      confirmButtonText: "Yes",
      cancelButtonText: "Cancel",
      closeOnConfirm: true,
      closeOnCancel: true,
      allowOutsideClick: false
    }, function(isConfirm){
      if (isConfirm) {
        $.ajax({
          type: 'POST',
          url: `/messages/archived`,
          data: {
            guids: collection
          },
          success: function(data){
            location.reload();
          },
          error: function(data){
          }
        });
      }
    });
  }
  else {
    swal("No Messages Selected", "Please select a message to archive.", "warning");
  }
});

$(document).on("click", ".archive_select_all", function(){
  if ($(this).prop("checked")) {
    $('.select-archive-message').prop('checked', true)
  }
  else {
    $('.select-archive-message').prop('checked', false)
  }
});

$(document).on("click", ".unread-message", function(){
  let collection = []
  let checkboxes = $('.show-message').find('.select-archive-message')
  checkboxes.each(function () {
    if($(this).prop('checked')){
      if ($(this).closest("tr").find(".read-status").text() == "Read") {
        let guid = $(this).attr('data-message-guid')
        collection.push(guid)
      }
    }
  })
  if(collection.length > 0 ){
    swal({
      title: "Mark as Unread messages",
      text: "Would you like to unread these messages?" ,
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#1ab394",
      confirmButtonText: "Yes",
      cancelButtonText: "Cancel",
      closeOnConfirm: true,
      closeOnCancel: true,
      allowOutsideClick: false
    }, function(isConfirm){
      if (isConfirm) {
        $.ajax({
          type: 'POST',
          url: `/messages/unread`,
          data: {
            guids: collection
          },
          success: function(data){
            location.reload();
          },
          error: function(data){
          }
        });
      }
    });
  }
  else {
    swal("No Read Messages Selected", "Please select a read message to mark as unread.", "warning");
  }
});

// window.selectUserRecipient = selectUserRecipient;
window.send_message = send_message;
window.clearMessage = clearMessage;
window.user_search_reset = user_search_reset;
window.client_search_reset = client_search_reset;
window.user_select_row_click = user_select_row_click;
window.select_recipient = select_recipient;
window.sendReply = sendReply;
